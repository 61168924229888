import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: "Liste des utilisateurs",
            logo : null,
            show_progress:false,
            valeur_progression:0,
            vuetable: {
                moreParams: {},
                fields: [
                    // {
                    //     name: VuetableFieldCheckbox,
                    //     titleClass: 'text-center',
                    //     dataClass: 'text-center'
                    // },
                    {
                        name: 'nom',
                        title: 'Nom Complet',
                        sortField: 'nom_complet',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'login',
                        title: 'Login',
                        sortField: 'login',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'role',
                        title: 'Role',
                        sortField: 'role',
                        dataClass: 'text-left',
                        width: "170px",
                    },
                    {
                        name: 'statut',
                        title: 'Statut du compte',
                        sortField: 'statut',
                        dataClass: 'text-center',
                        titleClass: 'text-center',
                        width: "150px",
                    },
                    {
                        name: 'actions',
                        title:'-',
                        width: "50px",
                        titleClass: 'text-center',
                        dataClass: "text-center"
                    }

                ],
                sortOrder: [
                    { field: 'nom', direction: 'asc' }
                ],
                css: {
                    table: {
                        // tableClass: 'table table-striped table-bordered table-hovered',
                        tableClass: 'table-responsive w-100 d-block table-striped d-md-table table table-bordered table-hover',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Nom complet': 'nom',
                    'Login': 'login',
                    'Role': 'role',
                    'Statut du compte': 'statut'
                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            motCle: "",
            preview_link:this.BASE_URL+"/assets/img/profil_default.png",
            crudform: {
                id: "",
                nom: "",
                login: "",
                mdp: "",
                validation_mdp: "",
                role: "SIMPLE",
                statut: "ACTIVE",
                association: "aucun",
                point_vente_id: "",
                magasin_id: "",
                tresoreries_id: "",
            },
            crudmodaltitle: "Ajouter un utilisateur",
            listdata: {
                magasins: [],
                pointventes: []
            },
            error_password: "",

        }
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        editRow(rowData) {
            //alert("You clicked edit on" + JSON.stringify(rowData))
            axios.get(this.BASE_URL + "/users/get/" + rowData.id).then(response => {

                // console.log(response.data[0]);

                this.crudform.id = response.data[0].id;
                this.crudform.nom = response.data[0].nom;
                this.crudform.login = response.data[0].login;
                this.crudform.photos = response.data[0].photos;
                this.crudform.mdp = "";
                this.crudform.validation_mdp = "";
                this.crudform.role = response.data[0].role;
                this.crudform.statut = response.data[0].statut;
                this.crudform.association = "aucun";
                this.crudform.point_vente_id = "";
                this.crudform.tresoreries_id = response.data[0].tresoreries_id;
                this.crudform.magasin_id = "";
                this.preview_link = (response.data[0].photos !='' && response.data[0].photos != null) ?
                    this.BASE_URL+"/upload/users/"+response.data[0].photos : this.BASE_URL+"/assets/img/profil_default.png"

                console.log(this.preview_link)
                /*if (response.data[0].magasin_id == 0 && response.data[0].point_vente_id != 0) {
                    this.crudform.association = "point_vente";
                    this.crudform.point_vente_id = response.data[0].point_vente_id;
                }
                if (response.data[0].magasin_id != 0 && response.data[0].point_vente_id == 0) {
                    this.crudform.association = "magasin";
                    this.crudform.magasin_id = response.data[0].magasin_id;
                }*/

            });
            this.crudmodaltitle = "Editer un utilisateur";
            this.openModal();
        },
        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Supprimer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        axios.post(that.BASE_URL + "/users/delete/" + rowData.id).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                that.setFilter();
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.filter = this.motCle;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("crudmodal");
        },
        closeModal() {
            this.$bvModal.hide("crudmodal");
        },
        addRow() {
            this.crudmodaltitle = "Ajouter un utilisateur";
            this.crudform = {
                id: "",
                nom: "",
                login: "",
                mdp: "",
                validation_mdp: "",
                role: "SIMPLE",
                statut: "ACTIVE",
                association: "aucun",
                magasin_id: "",
                point_vente_id: "",
                tresoreries_id: "",
            };
            this.preview_link=this.BASE_URL+"/assets/img/profil_default.png";
            // console.log(this.crudform);
            this.openModal();
        },
        initLogo: function() {
            this.preview_link =this.BASE_URL+"/assets/img/profil_default.png";
            this.crudform.photos = "";
            this.logo = null;
        },
        uploadImage(event) {
            console.log(event.target.files[0]);

            this.show_progress = true;
            const URL = this.BASE_URL + '/import/uploadaction';

            let data = new FormData();
            data.append('name', 'file-input');
            data.append('file-input', event.target.files[0]);
            console.log(event.target.files[0]);
            var files = event.target.files || event.dataTransfer.files;

            this.createImage(files[0]);

            let config = {
                header: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    this.valeur_progression = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                }
            }
            var that = this;
            axios.post(
                URL,
                data,
                config
            ).then(
                response => {
                    console.log('image upload response > ', response);
                    that.show_progress = false;
                    that.crudform.photos = response.data.upload_data.file_name;

                }
            ).catch(error => {
                console.log(error);
            })
        },

        createImage(file) {
            var reader = new FileReader();
            var that = this;
            reader.onload = (e) => {
                that.preview_link = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        onSubmit() {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Continuer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = that.crudform.id == '' ? that.BASE_URL + "/users/addaction" : that.BASE_URL + "/users/editaction";
                        axios.post(link, $("#formulaire").serialize()).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                that.closeModal();
                                that.setFilter();
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        fetchData: function() {
            axios.get(this.BASE_URL + "/users/fetchdata").then(response => {
                this.listdata.magasins = response.data.magasins;
                this.listdata.pointventes = response.data.pointventes;
                this.listdata.tresoreries = response.data.tresoreries;
            });
        },
        isValidPassword: function() {
            if (this.crudform.mdp != this.crudform.validation_mdp) {
                this.error_password = this.$t('users.error_password');
            } else {
                this.error_password = "";
            }
        }
    },
    watch: {

    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);

        this.motCle = "";
        this.criteriacol = "";
        this.fetchData();
    },
    mounted: function() {

        console.log(process);
        console.log(process.env);
        console.log(process.env.STRIPE_TOKEN);

    }
}
